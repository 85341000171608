<template>
  <div class="transportation-driver">
    <h1>This is the Transportation Driver page</h1>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
